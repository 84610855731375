import Header from '../../components/Header/Header'
import NavBar from '../../components/NavBar/NavBar'
import Insights from '../Insights/Insights'
import './home.css'
import React, { useEffect, useState } from 'react'
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
    useMsal,
} from '@azure/msal-react'
import { loginRequest } from '../../config/authConfig'
import ChatbotLogo from '../../ImagesMisc/ChatBotLogo.png'
import { useDispatch } from 'react-redux'
import {
    setRawPreferences,
    setUserInfo,
} from '../../redux/userPreferencesSlice'
import { userInfoFetch } from '../../redux/userPreferenceFetch'
import MobileHeader from '../../components/MobileHeader/MobileHeader'
import MobileInsights from '../../components/MobileInsights/MobileInsights'
import NewMobileNavBar from '../../components/MobileNavBar/NewMobileNavBar'
import LoginPage from './LoginPage'
import MobileLoginPage from '../../components/MobileLoginPage/MobileLoginPage'
import { Container } from '@mui/material'

const app_env = process.env.REACT_APP_ENV

const WrappedNavBar = () =>
    window.innerWidth > 1514 ? <NavBar /> : <NewMobileNavBar />

const WrappedHeader = () =>
    window.innerWidth > 940 ? <Header /> : <MobileHeader />

const WrappedInsights = () =>
    window.innerWidth > 1024 ? <Insights /> : <MobileInsights />

const deviceWidth = window.innerWidth

const getUsername = (payload) => {
    if (app_env === 'local') {
        return payload?.email?.split('@')[0]
    } else {
        return (
            payload[0]?.name?.split(',')[1] ?? payload[0]?.name?.split(',')[0]
        )
    }
}
function Home() {
    const { instance, accounts } = useMsal()
    const currentUser = accounts ? accounts[0]?.username : null
    // const username = getUsername(accounts)

    const dispatch = useDispatch()
    const [deviceWidth, setDeviceWidth] = useState(window.innerWidth)

    useEffect(() => {
        // Function to update state to current window width
        const handleResize = () => {
            setDeviceWidth(window.innerWidth)
        }

        // Add event listener for window resize
        window.addEventListener('resize', handleResize)

        // Log the device width for demonstration
        console.log('deviceWidth', deviceWidth)

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [deviceWidth]) // Depend on deviceWidth to re-run the effect, but avoid reload

    const fetchUserData = async () => {
        // Check if a user is logged in.
        if (accounts && accounts.length > 0) {
            // Get the email of the logged-in user.
            // const currentUser = 'keidsouza@deloitte.com'

            const result = await userInfoFetch(currentUser)

            if (result) {
                const currentPreference = result?.preference ?? []
                result['currentPreference'] = currentPreference
                result['username'] = getUsername(accounts)
                // Call the action to fetch user preferences with the email ID.
                dispatch(setUserInfo(result))
                dispatch(setRawPreferences(result.preference))
            } else {
                // Handle the case where result is undefined or null.
                console.error(
                    'Error: userInfoFetch returned undefined or null.'
                )
            }
        }
    }
    const fetchUserDataLocal = async () => {
        // Check if a user is logged in.
        // Get the email of the logged-in user.
        const currentUser = process.env.REACT_APP_CURRENT_USER
        const result = await userInfoFetch(currentUser)
        if (result) {
            const currentPreference = result?.preference ?? []
            result['currentPreference'] = currentPreference
            result['username'] = getUsername(result)
            // Call the action to fetch user preferences with the email ID.
            dispatch(setUserInfo(result))
            dispatch(setRawPreferences(result.preference))
        }
    }

    useEffect(() => {
        if (app_env === 'local') {
            fetchUserDataLocal()
        } else {
            fetchUserData()
        }
    }, [dispatch, instance, accounts])
    if (app_env === 'local') {
        return (
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                }}
            >
                <WrappedHeader data-testid="header" />
                <div className="home-container">
                    <div>
                        <div
                            className="container-fluid"
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <WrappedInsights />
                        </div>
                        <div className="container-fluid1-table-data">
                            <div className="Api-list-flex" data-testid="navbar">
                                <WrappedNavBar />
                            </div>
                        </div>
                        <div>
                            <img
                                src={ChatbotLogo}
                                width="50px"
                                height="50px"
                                className="chatbot-logo-img"
                                alt="chatbot"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
            }}
        >
            <AuthenticatedTemplate>
                <WrappedHeader />

                <div className="home-container">
                    <div>
                        <div
                            className="container-fluid"
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <WrappedInsights />
                        </div>
                        <div className="container-fluid1-table-data">
                            <div
                                className="Api-list-flex"
                                data-testid="navbar"
                            >
                                <WrappedNavBar />
                            </div>
                        </div>
                        <div>
                            <img
                                src={ChatbotLogo}
                                width="50px"
                                height="50px"
                                className="chatbot-logo-img"
                                alt="chatbot"
                            />
                        </div>
                    </div>
                </div>

            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <div>
                    <Container
                        maxWidth={false}
                        style={{
                            display: 'flex',
                            height: '100vh',
                            flexDirection: 'row',
                            padding: 'none',
                        }}
                        className="homePageContainer"
                        data-testid="login-page"
                    >
                        {window.innerWidth > 770 ? (
                            <LoginPage />
                        ) : (
                            <MobileLoginPage />
                        )}
                    </Container>
                </div>
            </UnauthenticatedTemplate>
        </div>
    )
}

export default Home
