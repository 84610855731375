export const descriptionMapper = [
    'We currently support CMP, Cloud Network, AWS and GCP',

    'This option allows you to filter products/services in different parts of the world',

    'Choose to subscribe to Palo Alto/F5 device alerts',

    'Select your preferred Cloud Service Provider',

    'Start typing either device name / IP Address',
]

export const optionsDeviceType = [
    { value: 'palo-alto', label: 'Palo Alto' },
    { value: 'F5', label: 'F5' },
]

export const optionsCSP = [
    { value: 'Azure', label: 'Azure' },
    { value: 'AWS', label: 'AWS' },
    { value: 'GCP', label: 'GCP' },
]

export const optionsRegion = [
    { value: 'Americas', label: 'Americas' },
    { value: 'Europe', label: 'Europe' },
    { value: 'Asia Pacific', label: 'Asia Pacific' },
]

export const optionsCategory = [
    { value: 'CMP', label: 'CMP' },
    { value: 'cloudNetwork', label: 'Cloud Network' },
    { value: 'AZURE', label: 'Azure' },
    { value: 'AWS', label: 'AWS' },
    { value: 'GCP', label: 'GCP' },
]

export const optionsRegionAZURE = [
    { value: 'americas', label: 'Americas', active: true },
    { value: 'europe', label: 'Europe', active: false },
    { value: 'asia_pacific', label: 'Asia Pacific', active: false },
]

export const optionsRegionGCP = [
    { value: 'global', label: 'Global' },
    { value: 'americas', label: 'Americas' },
    { value: 'europe', label: 'Europe' },
    { value: 'africa', label: 'Africa' },
    { value: 'asia_pacific', label: 'Asia Pacific' },
    { value: 'middle_east', label: 'Middle East' },
]

export const optionsRegionAWS = [
    { value: 'global', label: 'Global' },
    // { value: 'south_america', label: 'South America' },
    { value: 'americas', label: 'Americas' },
    { value: 'europe', label: 'Europe' },
    // { value: 'africa', label: 'Africa' },
    // { value: 'asia_pacific', label: 'Asia Pacific' },
    // { value: 'middle_east', label: 'Middle East' },
]
