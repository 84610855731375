import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import Stack from '@mui/material/Stack'
import LegendList from '../LegendList/LegendList'
import '../../LoaderSpinner/loaderspinner.css'
import '../../pages/Insights/insights.css'
import axios from 'axios'
import { Container } from '@mui/material'
import { useSelector } from 'react-redux'
import MobileDarkMode from '../MobileDarkMode/MobileDarkMode'
import InstructionModal from '../Carousel/InstructionModal'
import MobileSubscriptionModal from '../MobileSubscriptionModal/MobileSubscriptionModal'
function MobileInsights(props) {
    const { rawPreferences } = useSelector((state) => state.userInfo) || {}

    const [loading, setLoading] = useState(false)
    const [alerts, setAlerts] = useState([''])
    const fetchAlertsUrl = process.env.REACT_APP_BACKEND_URL
    const [openModal, setOpenModal] = useState(false)
    const [preferenceLoading, setPreferenceLoading] = useState(true)
    useEffect(() => {
        if (rawPreferences && rawPreferences?.length > 0) {
            setPreferenceLoading(false)
        } else {
            setTimeout(() => {
                setPreferenceLoading(false)
            }, 2000)
        }
    }, [rawPreferences])

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const fetchAlertsData = async () => {
        axios
            .get(`${fetchAlertsUrl}/api/others/alerts`)
            .then((res) => {
                if (res) {
                    setLoading(false)
                    setAlerts(res.data)
                }
            })
            .catch((err) => console.log(err))
    }

    useEffect(() => {
        fetchAlertsData()
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            fetchAlertsData()
        }, 90000)

        //Clearing the interval
        return () => clearInterval(interval)
    }, [alerts])

    // const mockAlerts = [
    //     {
    //         _id: '657ab02503b851716445b74e',
    //         product_name: 'DSO.Fortify.ScanAdapter.FA AMericas Asia ETC ',
    //         location: 'Seattle',
    //         region: 'Americas',
    //         ip: null,
    //         categoryName: null,
    //         category: 'CMP',
    //         csp: null,
    //         availability: 85.21516666666666,
    //         timestamp: '1702541400000',
    //         ISODate: '12/14/2023 08:10 AM',
    //         alertMessage:
    //             'Availability of DSO.Fortify.ScanAdapter.FA (CMP) has dropped',
    //         createdAt: '2023-12-14T07:35:01.316Z',
    //         updatedAt: '2023-12-14T08:10:01.593Z',
    //         __v: 0,
    //     },
    //     {
    //         _id: '657ab02503b851716445b74e',
    //         product_name: 'AWS VPC API',
    //         location: 'Seattle',
    //         region: 'Americas',
    //         ip: null,
    //         categoryName: null,
    //         category: 'CMP',
    //         csp: null,
    //         availability: 0,
    //         timestamp: '1702541400000',
    //         ISODate: '12/14/2023 08:10 AM',
    //         alertMessage: 'Availability of AWS VPC API (CMP) has dropped',
    //         createdAt: '2023-12-14T07:35:01.316Z',
    //         updatedAt: '2023-12-14T08:10:01.593Z',
    //         __v: 0,
    //     },
    //     {
    //         _id: '657ab02503b851716445b74e',
    //         product_name: 'AWS Lambda',
    //         location: 'Seattle',
    //         region: 'Americas',
    //         ip: null,
    //         categoryName: null,
    //         category: 'CMP',
    //         csp: null,
    //         availability: 99,
    //         timestamp: '1702541400000',
    //         ISODate: '12/14/2023 08:10 AM',
    //         alertMessage: 'Availability of AWS VPC API (CMP) has dropped',
    //         createdAt: '2023-12-14T07:35:01.316Z',
    //         updatedAt: '2023-12-14T08:10:01.593Z',
    //         __v: 0,
    //     },
    // ]

    return (
        <>
            <MobileSubscriptionModal
                open={openModal}
                handleClose={handleCloseModal}
            // usersName={usersData}
            />

            <div className="insights-container mobile-insights">
                <div className="insight-flex">
                    <p className="service-insight-label-new">
                        Service Health Insights
                    </p>{' '}
                    <Button
                        className="updates-btn"
                        onClick={handleOpenModal}
                        disabled={preferenceLoading}
                    >
                        <p className="updates-txt">
                            {preferenceLoading ? ' Loading..' : 'Subscribe'}
                        </p>
                    </Button>
                    <div className="toggle-container mobile-toggle">
                        <div className="toggle-theme">
                            <MobileDarkMode />
                        </div>
                    </div>
                </div>

                {loading ? (
                    <div className="loader-container">
                        <div className="loader" data-testid="loader"></div>
                    </div>
                ) : (
                    <>
                        {' '}
                        <Container className="services-affected-container">
                            {alerts?.length > 0 && !loading ? (
                                <div className="alerts-container-mobile" data-testid="alerts-container">
                                    <InstructionModal alerts={alerts} />
                                </div>
                            ) : (
                                <div className="card-alert-container">
                                    <Stack
                                        direction="row"
                                        className="success-flex-alert"
                                    >
                                        <>
                                            <div className="info-description">
                                                {' '}
                                                <p className="operational-text">
                                                    All Services are Operational
                                                    today
                                                </p>
                                            </div>
                                        </>
                                    </Stack>
                                </div>
                            )}
                        </Container>
                    </>
                )}

                <LegendList />
            </div>
        </>
    )
}

export default MobileInsights
