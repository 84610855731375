import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CancelIcon from '@mui/icons-material/Cancel';
import { setUserInfo } from '../../redux/userPreferencesSlice';
import "./MobileCurrentSubscriptions.css";
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Fade from '@mui/material/Fade'
import ClearAllButton from '../MobileSubscriptionModal/clearAllButton';

export default function MobileCurrentSubscriptions({
    setHasUnsavedChanges,
    yesClicked,
}) {
    const dispatch = useDispatch()
    const { userInfo, rawPreferences } =
        useSelector((state) => state.userInfo) || {}
    const { preference, currentPreference } = userInfo || {}

    const [updatedSubscriptions, setUpdatedSubscriptions] = useState(
        userInfo.currentPreference
    )

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setUpdatedSubscriptions(userInfo.currentPreference)
    }, [userInfo])

    useEffect(() => {
        if (yesClicked) {
            const localUserData = {
                ...userInfo,
                preference: rawPreferences,
                currentPreference: rawPreferences,
            }

            dispatch(setUserInfo(localUserData))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [yesClicked])

    const handleRemoveSubscription = async (subscription) => {
        const [filteredSubscriptions, filteredCurrentSubscriptions] =
            await Promise.all([
                preference.filter((sub) => sub !== subscription),
                currentPreference.filter((sub) => sub !== subscription),
            ])
        setUpdatedSubscriptions(filteredSubscriptions)
        //const newResult = [...new Set(filteredSubscriptions)];

        const localUserData = {
            ...userInfo,
            preference: filteredSubscriptions,
            currentPreference: filteredCurrentSubscriptions,
        }

        dispatch(setUserInfo(localUserData))
        setHasUnsavedChanges(true)
    }

    const handleRemoveAllSubscriptions = () => {
        const localUserData = {
            ...userInfo,
            preference: [], // Clear the preferences in user data
            currentPreference: [], // Clear the current preferences in user data
        }
        setUpdatedSubscriptions([])
        dispatch(setUserInfo(localUserData)) // Update the Redux store
        setHasUnsavedChanges(true)
    }

    useEffect(() => {
        setLoading(true)
        if (currentPreference && updatedSubscriptions.length === 0) {
            setUpdatedSubscriptions(currentPreference)
        }
        setLoading(false)
    }, [currentPreference])


    return (
        <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center' }}>
            <div style={{ marginBottom: '10px', display: 'flex', flexDirection: "row", alignItems: 'flex-start', justifyContent: 'space-between', width: '100%' }}>
                <div style={{ display: 'flex', flexDirection: "row", alignItems: 'center' }}>
                    <Typography className="leftLabel-mobile">
                        Current Subscriptions -
                    </Typography>
                    <Tooltip
                        arrow
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        fontSize="small"
                        placement="top"
                        title="Size of the subscription is limited to 8 letters. To view the full name, please hover over the subscription name."
                    >
                        <span>
                            <InfoOutlinedIcon className="info-icon-alerts-modal" />
                        </span>
                    </Tooltip>
                </div>
                <div>
                    <ClearAllButton clearAllClicked={handleRemoveAllSubscriptions} />
                </div>
            </div>
            <div className="subscription-text-area-mobile">
                <div className="subscription-items-mobile">
                    {loading ? (
                        <div className="Loading" data-testid="loading">
                            <p>Loading ......</p>
                        </div>
                    ) : updatedSubscriptions && updatedSubscriptions.length > 0 ? (
                        <>
                            {updatedSubscriptions.map((subscription, index) => (
                                <div key={index} className="subscription-item">
                                    <span className="subscription-name-mobile">
                                        {subscription.length > 40 ? (
                                            <span title={subscription}>
                                                {subscription.slice(0, 5)}...
                                            </span>
                                        ) : (
                                            subscription
                                        )}
                                    </span>
                                    <CancelIcon
                                        data-testid="cancel-icon"
                                        type="button"
                                        className="remove-icon"
                                        onClick={() => handleRemoveSubscription(subscription)}
                                    />
                                </div>
                            ))}

                        </>
                    ) : (
                        <div>
                            <p>Please select a service / product from below</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
