import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import './current-subscriptions.css'
import { useEffect } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import { setUserInfo } from '../../redux/userPreferencesSlice'
export default function CurrentSubscriptions({
    setHasUnsavedChanges,
    yesClicked,
}) {
    const dispatch = useDispatch()
    const { userInfo, rawPreferences } =
        useSelector((state) => state.userInfo) || {}
    const { preference, currentPreference } = userInfo || {}

    const [updatedSubscriptions, setUpdatedSubscriptions] = useState(
        userInfo.currentPreference
    )

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setUpdatedSubscriptions(userInfo.currentPreference)
    }, [userInfo])

    useEffect(() => {
        if (yesClicked) {
            const localUserData = {
                ...userInfo,
                preference: rawPreferences,
                currentPreference: rawPreferences,
            }

            dispatch(setUserInfo(localUserData))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [yesClicked])

    const handleRemoveSubscription = async (subscription) => {
        const [filteredSubscriptions, filteredCurrentSubscriptions] =
            await Promise.all([
                preference.filter((sub) => sub !== subscription),
                currentPreference.filter((sub) => sub !== subscription),
            ])
        setUpdatedSubscriptions(filteredSubscriptions)
        //const newResult = [...new Set(filteredSubscriptions)];

        const localUserData = {
            ...userInfo,
            preference: filteredSubscriptions,
            currentPreference: filteredCurrentSubscriptions,
        }

        dispatch(setUserInfo(localUserData))
        setHasUnsavedChanges(true)
    }

    const handleRemoveAllSubscriptions = () => {
        const localUserData = {
            ...userInfo,
            preference: [], // Clear the preferences in user data
            currentPreference: [], // Clear the current preferences in user data
        }
        setUpdatedSubscriptions([])
        dispatch(setUserInfo(localUserData)) // Update the Redux store
        setHasUnsavedChanges(true)
    }

    const sanitizeString = (text) => {
        if (text) {
            const title = text?.replace(/---/g, '-')
            return title
        }
    }

    useEffect(() => {
        setLoading(true)
        if (currentPreference && updatedSubscriptions.length === 0) {
            setUpdatedSubscriptions(currentPreference)
        }
        setLoading(false)
    }, [currentPreference])

    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="subscription-text-area">
                <div className="subscription-items">
                    {loading ? (
                        <div className="Loading" data-testid="loading">
                            <p>Loading ......</p>
                        </div>
                    ) : updatedSubscriptions &&
                      updatedSubscriptions.length > 0 ? (
                        <>
                            {updatedSubscriptions.map((subscription, index) => (
                                <div key={index} className="subscription-item">
                                    <span className="subscription-name">
                                        {subscription.length > 5 ? (
                                            <span
                                                title={sanitizeString(
                                                    subscription
                                                )}
                                            >
                                                {subscription.slice(0, 5)}...
                                            </span>
                                        ) : (
                                            subscription
                                        )}
                                    </span>
                                    <CancelIcon
                                        data-testid="cancel-icon"
                                        type="button"
                                        className="remove-icon"
                                        onClick={() =>
                                            handleRemoveSubscription(
                                                subscription
                                            )
                                        }
                                    />
                                </div>
                            ))}
                            <div>
                                <span>
                                    <button
                                        className="update-button"
                                        data-testid="clear-all"
                                        onClick={handleRemoveAllSubscriptions}
                                    >
                                        Clear All
                                    </button>
                                </span>
                            </div>
                        </>
                    ) : (
                        <div>
                            <p>Please select a service / product from below</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}
