import React from "react";
import logo from "../Header/header-logo.svg";
import "../Header/header.css";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import StringAvatar from "../Avatar/stringAvatar";
import { SignOutButton } from "../Login/SignOutButton";
import { Stack } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";

function MobileHeader() {
    const isAuthenticated = useIsAuthenticated();
    const { accounts } = useMsal();
    return (
        <nav className="Navbar-HeaderD">
            <a href="https://www2.deloitte.com" className="link-a">
                <img alt="Deloitte" src={logo} className="image-logo"></img>
            </a>
            <span className="span-logo">
                <div className="CMP-label">CMP</div>
            </span>
            <span className="search-name">
                <span className="input-search-name-box"></span>
            </span>

            <div height="40" width="40" className="name-flex">
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    {/* <Tooltip title="Dsouza, Keith">
            <span>
              <StringAvatar username="Dsouza, Keith" />
            </span>
          </Tooltip> */}

                    <div>
                        <Tooltip
                            arrow
                            TransitionComponent={Fade}
                            TransitionProps={{ timeout: 600 }}
                            placement="bottom"
                            title={"Logged in as " + accounts[0]?.name}
                        >
                            <span>
                                {isAuthenticated ? (
                                    <StringAvatar username={accounts[0]?.name} />
                                ) : (
                                    <AccountCircleIcon
                                    data-testid="account-circle-icon"
                                        sx={{ color: "black", fontSize: "35px", cursor: "pointer" }}
                                    />
                                )}
                            </span>
                        </Tooltip>

                    </div>

                    <SignOutButton />
                </Stack>
            </div>
        </nav>
    );
}

export default MobileHeader;
