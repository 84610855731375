import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import Swal from "sweetalert2";
import "./sweetAlertCustom.css";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
];

const InactiveLogout = ({ children }) => {
  const { accounts, instance, inProgress } = useMsal();

  let timer;

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleTimer();
      });
    });
  }, []);

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  const handleTimer = () => {
    timer = setTimeout(() => {
      resetTimer();
      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      handleLogout();
    }, 360000);
  };

  const handleLogout = async () => {

    if (accounts.length === 0) {

    } else {


      Swal.fire({
        title: "Session Expired",
        text: "Your login session has expired. Please login again.",
        icon: "warning",
        confirmButtonColor: "#006161",
        confirmButtonText: "OK",
      }).then((result) => {

        if (result.isConfirmed) {
          instance.logoutRedirect({
            postLogoutRedirectUri: "/",
          });
        }
      });
    }
  };

  return children;
};

export default InactiveLogout;
