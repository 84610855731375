import React from 'react'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import './subscriptionModal.css'
import { useSelector } from 'react-redux'
function WelcomeContainer({ onClose }) {
    const { userInfo } = useSelector((state) => state.userInfo) || {}
    const { username } = userInfo || {}
    return (
        <div className="subscription-modal-welcome-msg-container">
            <Typography
                id="modal-modal-description"
                sx={{ fontSize: '24px', fontWeight: '300' }}
            >
                {`Hey ${
                    username ? username : 'User'
                }, please choose Products / Services you wish to
receive alerts for`}
            </Typography>

            {/* Close Icon */}
            <CloseIcon data-testid="close-icon" onClick={onClose} style={{ cursor: 'pointer' }} />
        </div>
    )
}

export default WelcomeContainer
