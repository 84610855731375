import React from 'react';
import './close-button.css';

const CloseButton = ({ handleClose, mobileStyle }) => {
    return (
        <button
            className="close-button"
            onClick={handleClose}
            style={mobileStyle} // Add the style attribute here
            data-testid="close-button"
        >
            Close
        </button>
    );
};

export default CloseButton;