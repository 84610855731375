export const subscriptionModalBoxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  p: 4,
  width: "946px",
  minHeight: "500px",
  borderRadius: "10px",
  border: "none",
  background: "#FFF",
  boxShadow:
    " 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
};

export const styleConfirmationModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "1rem",
  width: "29rem",
  minHeight: "200px",
  borderRadius: "10px",
  border: "none",
  background: "#FFF",
  boxShadow:
    " 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
};

export const MobileSubscriptionModalBoxStyle = {
  position: "absolute",
  top: "48%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "22px",
  maxWidth: "380px",
  margin: "1rem 0rem 1rem 0rem",
  minHeight: "500px",
  borderRadius: "10px",
  border: "none",
  background: "#FFF",
  boxShadow:
    " 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
};

export const MobileStyleConfirmationModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  padding: "1rem",
  width: "20rem",
  minHeight: "200px",
  borderRadius: "10px",
  border: "none",
  background: "#FFF",
  boxShadow:
    " 0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)",
};
