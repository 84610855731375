import React from "react";
import Avatar from "@mui/material/Avatar";

function StringAvatar(props) {
  const avatarDisplay = (name) => {
    let initials = name[0];

    const hasComma = name?.includes(",");

    if (hasComma) {
      initials = `${name.split(",")[1][1]}${name.split(",")[0][0]}`;
    }

    return {
      sx: {
        bgcolor: "#999999",
      },

      children: initials,
    };
  };
  return (
    <div>
      {" "}
      <Avatar {...avatarDisplay(props?.username)} />
    </div>
  );
}

export default StringAvatar;
