// userInfoSlice.js

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userInfo: null,
  status: "idle", // 'idle', 'loading', 'succeeded', 'failed'
  error: null,
  rawPreferences: [],
};

const userInfoSlice = createSlice({
  name: "userInfo",
  initialState,
  reducers: {
    // Define your actions here
    setUserInfo: (state, action) => {
      state.userInfo = action.payload;
    },
    setRawPreferences: (state, action) => {
      state.rawPreferences = action.payload;
    },


    // ... other actions
  },
});

export const { setUserInfo, setRawPreferences } = userInfoSlice.actions;

export const userInfoReducer = userInfoSlice.reducer;

