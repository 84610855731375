import * as React from 'react'
import { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import { Col, Row, Container, ButtonGroup } from 'react-bootstrap'
import FilterListIcon from '@mui/icons-material/FilterList'
import success from '../../info-logos/Success-display.png'
import error from '../../info-logos/Error-display.png'
import warning from '../../info-logos/Warning-display.png'
import LineGraph from '../LineGraph/LineGraph'
import './healthcard.css'
import Tooltip from '@mui/material/Tooltip'
import Fade from '@mui/material/Fade'
import MobileLineGraph from '../MobileLineGraph/MobileLineGraph'
function TableHealth(props) {
    const { loading } = props

    const [, setAnim] = useState(false)
    const [active2, setActive] = useState(null)
    // const [rowData, setRowData] = useState(data);
    const [dailyGapCSS, setDailyGapCSS] = useState(false)
    const [show, set_show] = useState('')
    const [, setExpandData] = useState(['']) //use at line 353
    const elementRef = React.useRef([])
    const [arrowDisable, setArrowDisable] = useState(true)
    const [graphData] = useState(props?.apiData)
    const [sortStatus, setSortStatus] = useState(true)
    const [apiData, setApiData] = useState(props.apiData ? props.apiData : [])

    useEffect(() => {
        setApiData(props.apiData)
    }, [props.apiData])

    const categoryLabels = {
        0: 'Products',
        1: 'Devices',
    }

    const buttonText = categoryLabels[props.category] || 'Services'

    const handleSortIf = () => {
        let sorted = apiData.data.sort((b, a) => a._id.localeCompare(b._id))
        setApiData({ data: sorted, empty: apiData.empty })
        setSortStatus(false)
    }

    const handleSortElse = () => {
        let sorted = apiData.data.sort((a, b) => a._id.localeCompare(b._id))

        setApiData({ data: sorted, empty: apiData.empty })
        setSortStatus(true)
    }

    const showErrorMessage = (message) => {
        return message ? message : 'Coming very soon...'
        // if (message) {
        //     return me
        // }
        // if (props?.category !== 3 && props?.category !== 4) {
        //     return 'We are currently facing some technical difficulties. Please try again after sometime'
        // } else {
        //     return 'Coming very soon...'
        // }
    }

    const sortFunctions = {
        true: handleSortIf,
        false: handleSortElse,
    }
    const handleSort = (status) => {
        const sortingFunction = sortFunctions[status]
        sortingFunction()
    }

    const renderSwitch = (param) => {
        return param < 10 ? error : param > 11 && param < 90 ? warning : success
    }
    const cloudNetworkMapper = (param) => {
        return param === 0 ? error : param < 1 ? warning : success
    }

    const Interchange_to_hourly = () => {
        // setLoading(true)
        setDailyGapCSS(false)
    }

    //useEffect to load and fetch table data
    // useEffect(() => {
    //     let load_initiate = setTimeout(() => {
    //         setLoading(false)
    //     }, 2000)

    //     return () => {
    //         clearTimeout(load_initiate)
    //     }
    // }, [dailyGapCSS, loading])

    const handleExpandRow = (index, key, data) => () =>
        Expand_row(index, key, data)

    const Expand_row = (index, key, data1) => {
        let index_new = 'row' + index
        setAnim(true)

        if (show === index_new) {
            set_show('')
            setExpandData([])
            setAnim(false)
        } else {
            set_show('row' + index)
            setExpandData(data1)
            setAnim(true)
        }
    }

    if (window.innerWidth < 1025) {
        return (
            <div>
                {loading ? (
                    <div className="table-loader-container">
                        <div className="loader" />
                    </div>
                ) : apiData && apiData?.data && apiData?.data.length > 0 ? (
                    apiData?.data?.map((api, index) => (
                        <>
                            <Row className="row-chart" key={index} xs={12}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        gap: '10px',
                                    }}
                                >
                                    <div
                                        className="header-api-mobile"
                                        style={{ minWidth: '150px' }}
                                    >
                                        <label className="api-name-list">
                                            {/* Incase characters are more than 30, put ellipsis. */}
                                            {api?._id?.length > 60
                                                ? api._id?.substring(0, 20) +
                                                  `...`
                                                : api._id}
                                        </label>
                                        <label className="response-time-mobile">
                                            {api?.data && api?.data[0]?.ip
                                                ? api?.data[0]?.ip?.split(
                                                      ':'
                                                  )[0]
                                                : ''}
                                        </label>
                                    </div>
                                    <div
                                        style={{
                                            maxWidth: window.innerWidth * 1,
                                            overflowX: 'auto',
                                        }}
                                        id="status-indicator-container"
                                    >
                                        <div
                                            className={'hour-display'}
                                            id="status-indicator"
                                            style={{
                                                width: '980px',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            {' '}
                                            {api?.data?.map((data1, key) => (
                                                <span key={key}>
                                                    <img
                                                        src={
                                                            data1.category ===
                                                            'cloudNetwork'
                                                                ? cloudNetworkMapper(
                                                                      data1.availability
                                                                  )
                                                                : renderSwitch(
                                                                      data1.availability
                                                                  )
                                                        }
                                                        className="row-alert-render"
                                                        onClick={handleExpandRow(
                                                            index,
                                                            key,
                                                            data1
                                                        )}
                                                        style={{
                                                            marginBottom:
                                                                '20px',
                                                        }}
                                                        alt="alert-message"
                                                    />
                                                </span>
                                            ))}
                                        </div>
                                        <MobileLineGraph
                                            graphData={
                                                graphData?.data[index]?.data
                                            }
                                        />
                                    </div>
                                </div>
                            </Row>
                        </>
                    ))
                ) : (
                    <div
                        className="coming-soon"
                        style={{ textAlign: 'center', fontSize: '18px' }}
                    >
                        <p>Coming very soon...</p>
                    </div>
                )}
            </div>
        )
    }
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                }}
            >
                <div>
                    <Tooltip
                        arrow
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 600 }}
                        placement="right"
                        title={
                            sortStatus === true
                                ? 'Sort ' + buttonText + ' in descending order'
                                : 'Sort ' + buttonText + ' in ascending order'
                        }
                    >
                        <div
                            onClick={() => {
                                handleSort(sortStatus)
                            }}
                        >
                            <Button
                                variant="text"
                                className="Button-display-tablep"
                                data-testid="sort-button"
                                sx={{
                                    fontSize: '14px',
                                    fontWeight: '600',

                                    textTransform: 'unset',
                                    // color: "black",
                                }}
                            >
                                {buttonText}
                            </Button>
                            <IconButton aria-label="delete">
                                <FilterListIcon className="image-sort" />
                            </IconButton>
                        </div>
                    </Tooltip>
                </div>
                <div
                    // spacing={3}
                    className="hourly-daily-display"
                    direction="row"
                >
                    <Button
                        variant="text"
                        className="Button-display-tableh"
                        sx={{
                            textTransform: 'unset',
                            // color: "#75787B",
                        }}
                        disabled={true}
                    >
                        ( Note: Data auto-updates every 5 minutes )
                    </Button>
                    <Button
                        variant="text"
                        className="Button-display-tableh"
                        sx={{
                            textTransform: 'unset',
                            fontWeight: dailyGapCSS ? '400' : '700',
                        }}
                        onClick={() => Interchange_to_hourly()}
                    >
                        Hourly*
                    </Button>
                    <Button
                        variant="text"
                        className="Button-display-tableh"
                        sx={{
                            textTransform: 'unset',
                            fontWeight: 400,
                        }}
                        disabled={true}
                    >
                        *All time in UTC
                    </Button>
                </div>
            </div>
            <div>
                {apiData && apiData?.empty === 'false' ? (
                    apiData?.data &&
                    apiData?.data?.map((api, index) => (
                        <Row
                            className="row-chart"
                            key={index}
                            xs={12}
                            data-testid={`api-id-${index}`}
                        >
                            <Col xs={2}>
                                <div className="header-api">
                                    <p className="api-name-list">{api?._id}</p>
                                    <p className="response-time">
                                        {api?.data && api.data[0]?.ip
                                            ? api.data[0].ip?.split(':')[0]
                                            : ''}
                                    </p>
                                </div>
                            </Col>
                            <Col
                                id="column80"
                                xs={10}
                                ref={(el) => (elementRef.current[index] = el)}
                            >
                                <div
                                    className={'hour-display'}
                                    id="status-indicator"
                                >
                                    {api?.data?.map((data1, key) => (
                                        <span key={key}>
                                            <img
                                                src={
                                                    data1.category ===
                                                    'cloudNetwork'
                                                        ? cloudNetworkMapper(
                                                              data1.availability
                                                          )
                                                        : renderSwitch(
                                                              data1.availability
                                                          )
                                                }
                                                className="row-alert-render"
                                                onClick={handleExpandRow(
                                                    index,
                                                    key,
                                                    data1
                                                )}
                                                alt="alert-message"
                                            />
                                        </span>
                                    ))}
                                </div>
                                <Row>
                                    <LineGraph
                                        graphData={graphData?.data[index]}
                                    />
                                </Row>
                            </Col>
                        </Row>
                    ))
                ) : (
                    <div
                        className="coming-soon"
                        style={{
                            textAlign: 'center',
                            fontSize: '14px',
                            paddingTop: '5rem   ',
                        }}
                    >
                        <p>{showErrorMessage()}</p>
                    </div>
                )}
            </div>
        </>
    )
}

export default TableHealth
