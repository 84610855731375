import successGIF from './success.gif'

export const handlePreferenceSave = async (
    closeModalAndClearStates,
    updatePreferences,
    accounts,
    preference,
    toast
) => {
    try {
        // Show a loading toast
        const loadingToast = toast.loading('Loading', {
            style: {
                minWidth: '300px', // Increase the minimum width as needed
            },
        })

        await closeModalAndClearStates()

        const updateResult = await updatePreferences(
            accounts[0]?.username || process.env.REACT_APP_CURRENT_USER,
            preference
        )

        // Delay for 1 second (1000 milliseconds) for better UI perspective
        await new Promise((resolve) => setTimeout(resolve, 1000))
        // Check if the API response indicates success
        if (updateResult && updateResult.success) {
            // Show a success toast when the update was successful
            toast(
                <div>
                    <img
                        src={successGIF}
                        height={'40px'}
                        width={'40px'}
                        alt="Success Icon"
                    />
                    {updateResult.msg}
                </div>,
                {
                    duration: 5000,
                    style: {
                        minWidth: 'fit-content', // Increase the minimum width as needed
                    },
                }
            )
        } else {
            // Show an error toast when the update was not successful
            toast.error(
                'Failed to update preferences. Please try again.' +
                    updateResult.msg,
                {
                    duration: 6000,
                    style: {
                        minWidth: 'fit-content', // Increase the minimum width as needed
                    },
                }
            )
        }

        // Dismiss the loading toast after the artificial delay
        toast.dismiss(loadingToast)
    } catch (err) {
        // Show an error toast if there was an error with the API request
        toast.error(`An error occurred: ${err.toString()}`, {
            style: {
                minWidth: '300px', // Increase the minimum width as needed
            },
        })
    }
}
