import React from 'react'
import Select from 'react-select'
import Snackbar from '@mui/material/Snackbar'

function ReusableDropdown({ placeholder, values, onChangeUpdate, disabled }) {
    const handleUpdate = (selectedOption) => {
        onChangeUpdate(selectedOption.value) // Pass the selected value to the parent callback
    }
    const [open, setOpen] = React.useState(false)

    const handleClick = () => {
        setOpen(true)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }

    return (
        <>
            <Snackbar
                open={open}
                autoHideDuration={2500}
                onClose={handleClose}
                ContentProps={{
                    style: {
                        backgroundColor: 'rgb(213 64 51)',
                        fontSize: '13px',
                        color: 'white',
                    },
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                message="Dropdown disabled. Please select an option in the dropdown above."
            />
            <div
                style={{
                    minWidth: window.innerWidth > 600 ? '32.5rem' : '19rem',
                }}
            >
                {disabled ? (
                    <div
                        style={{
                            width: '100%',
                            padding: '7px',
                            borderRadius: '10px',
                            cursor: 'not-allowed',
                            display: 'flex',
                            flexDirection: 'row',
                            border: 'var(--disable-border)',
                        }}
                        onClick={handleClick}
                    >
                        <div
                            style={{
                                flex: '85%',
                                fontSize: '13px',
                                color: 'hsl(0 0% 53% / 1)',
                            }}
                        >
                            Disabled
                        </div>
                        <div
                            style={{
                                padding: '0px 5px 0px 12px',
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                borderLeft: 'var(--disable-border-left)',
                            }}
                        >
                            <svg
                                height="20"
                                width="20"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                                focusable="false"
                                className="css-tj5bde-Svg"
                                style={{ fill: 'var(--disable-svg-fill)' }}
                            >
                                <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                            </svg>
                        </div>{' '}
                    </div>
                ) : (
                    <Select
                        options={!disabled ? values : []}
                        defaultValue={null}
                        placeholder={placeholder}
                        onChange={handleUpdate}
                        noOptionsMessage={() => 'No service found...'}
                        styles={{
                            clearIndicator: (baseStyles) => ({
                                ...baseStyles,
                                color: 'red',
                            }),

                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderRadius: '10px',
                                borderColor: state.isFocused
                                    ? '#86bc25'
                                    : 'rgba(0, 0, 0, 0.20)',
                                boxShadow: state.isFocused ? '#86bc25' : 'gray',
                                '&:hover': {
                                    borderColor: state.isFocused
                                        ? '#86bc25'
                                        : 'gray',
                                },
                            }),
                            multiValueRemove: (baseStyles, state) => ({
                                ...baseStyles,
                                color: state.isFocused ? 'red' : 'gray',
                                backgroundColor: state.isFocused
                                    ? 'black'
                                    : '#86bc25',
                            }),
                            singleValue: (baseStyles) => ({
                                ...baseStyles,
                                fontSize: '14px',
                            }),
                            placeholder: (baseStyles) => ({
                                ...baseStyles,
                                fontSize: '14px',
                            }),
                        }}
                    />
                )}
            </div>
        </>
    )
}

export default ReusableDropdown
