import { Button, Container } from '@mui/material'
import React from 'react'
import { useMsal } from '@azure/msal-react'
import { loginRequest } from '../../config/authConfig'


function MobileLoginPage() {
    const { instance } = useMsal()

    const handleLogin = (loginType) => {
        if (loginType === 'popup') {
            if (instance && typeof instance.loginRedirect === 'function') {
            instance.loginRedirect(loginRequest).catch((e) => { console.log(e)})
        }
    }
    }
    return (<>
        <Container
            maxWidth={false}
            style={{
                display: 'flex',
                height: '100vh',
                flexDirection: 'row',
                padding: 'none',
            }}
            className="homePageContainer"
        >
            <div className="loginPart-mobile">
                <div className="label-button-container">
                    {' '}
                    <div className="welcomeText">
                        Welcome to the
                        <p className="portal-name">
                            Service Status Dashboard
                        </p>
                    </div>

                    <Button
                        className="login-button"
                        onClick={() => handleLogin('popup')}
                        data-testid="login-button"
                    >
                        Log in
                    </Button>
                </div>
            </div>

        </Container>
    </>
    )
}


export default MobileLoginPage  