import { api } from '../axiosWrapper'

export const userInfoFetch = async (emailId) => {
    const API_ENDPOINT = `${process.env.REACT_APP_BACKEND_URL}/api/others/findUserPreferences`
    try {
        // Make an authenticated POST API call using the access token obtained from react-msal.
        const response = await api.post(API_ENDPOINT, { email: emailId })
        const { data } = response || {}
        const userInfo = data
        return userInfo
    } catch (error) {
        // Handle any errors here
        console.error('Error fetching user preferences:', error)
        throw error
    }
}
