import React, { useState, useEffect, useCallback } from "react";
import { ReactComponent as Sun } from "./Sun.svg";
import { ReactComponent as Moon } from "./Moon.svg";
import "./darkmode.css";
import Tooltip from "@mui/material/Tooltip";
import Fade from "@mui/material/Fade";

const DarkMode = () => {
 
  const [title, setTitle] = useState("Switch to dark mode");

  const setDarkMode = useCallback(() => {
    document.querySelector("body").setAttribute("data-theme", "dark");
    document.body.style.backgroundColor = "#292929";
    localStorage.setItem("selectedTheme", "dark");
  }, []);

  const setLightMode = useCallback(() => {
    document.querySelector("body").setAttribute("data-theme", "light");
    document.body.style.backgroundColor = "white";
    localStorage.setItem("selectedTheme", "light");
  }, []);

  const selectedTheme = localStorage.getItem("selectedTheme");
  if (selectedTheme === "dark") {
    setDarkMode();
  } else {
    setLightMode();
  }

  const toggleTheme = (e) => {
    if (e.target.checked) {
      requestAnimationFrame(() => {
        setDarkMode();
        setTitle("Switch to light mode");
      });
    } else {
      requestAnimationFrame(() => {
        setLightMode();
        setTitle("Switch to dark mode");
      });
    }
  };

  return (
    <Tooltip
      arrow
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 600 }}
      placement="right"
      title={title}
    >
      <div className="dark_mode" data-testid="dark-mode">
        <input
          className="dark_mode_input"
          type="checkbox"
          id="darkmode-toggle"
          onChange={toggleTheme}
          defaultChecked={selectedTheme === "dark"}
          data-testid="darkmode-toggle"
        />
        <label className="dark_mode_label" htmlFor="darkmode-toggle">
          <Sun />
          <Moon />
        </label>
      </div>
    </Tooltip>
  );
};

export default DarkMode;
