// api.js

import axios from 'axios'

const serverUrl = process.env.REACT_APP_BACKEND_URL
axios.defaults.withCredentials = true

export const setCookie = async () => {
    return axios.get(`${serverUrl}/set-cookie`)
}
setCookie()

export const api = axios.create({
    baseURL: serverUrl, // Replace with your API base URL
})
