import React from 'react'
import './saveButton.css'
const SaveButton = ({ handleSave, mobileStyle }) => {
   
    return (
        <button
            className="save-button"
            onClick={handleSave}
            style={mobileStyle}
            data-testid="save-button"
          
        >
            Save
        </button>
    )
}

export default SaveButton
