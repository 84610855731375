import React, { useEffect, useState } from 'react'
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    ResponsiveContainer,
} from 'recharts'
import './linegraph.css'
export const formatXAxis = (tickItem) => {
    return tickItem.substring(11, 16)
}

//custom tooltip
export default function LineGraph(props) {
    const CustomTooltip = ({ active, payload, label }) => {
        let param = payload[0]?.payload?.availability
        if (active && payload && payload.length) {
            return (
                <div className="TooltipBox">
                    <div className="custom-tooltip">
                        <p className="TooltipLabel">DateTime : </p>
                        <p className="TooltipData">{` ${payload[0]?.payload?.ISODate ||
                            payload[0]?.payload?.hour
                            }`}</p>
                        {/* <p className="label">{`Availability : ${payload[0].payload.availability}`}</p> */}
                    </div>
                    <div className="custom-tooltip">
                        <p className="TooltipLabel">Availability : </p>
                        <p
                            className="TooltipData"
                            style={{
                                color:
                                    param === 1
                                        ? '#86bc25'
                                        : param < 10
                                            ? 'red'
                                            : param > 11 && param < 90
                                                ? '#ed8b00'
                                                : '#86bc25',
                            }}
                        >
                            {payload[0]?.payload?.category === 'cloudNetwork'
                                ? `${payload[0]?.payload?.availability * 100}`
                                : `${payload[0]?.payload?.availability?.toFixed(
                                    2
                                )}`}
                        </p>
                    </div>
                    {/* </div> */}
                </div>
            )
        }

        return null
    }

    const [loading, setLoading] = useState(true)

    let graphData = props?.graphData?.data

    const formatXAxis = (tickItem) => {
        return tickItem.substring(11, 16)
    }

    useEffect(() => {
        let load_initiate = setTimeout(() => {
            setLoading(false)
        }, 1500)

        return () => {
            clearTimeout(load_initiate)
        }
    }, [props])

    return (
        <>
            {loading ? (
                <div className="loader-graph" data-testid="loader-graph"></div>
            ) : (
                <div>
                    {props?.graphData?.data?.length > 0 ? (
                        <LineChart
                            height={70}
                            width={
                                document.getElementById('status-indicator')
                                    .offsetWidth * 1.012
                            }
                            // fill="rgba(217, 217, 217, 0.5)"
                            // background={{ fill: "rgba(217, 217, 217, 0.5)" }}
                            style={{
                                marginLeft: '-15px',
                            }}
                            data={graphData}
                            margin={{ top: 5, left: 20, right: 3, bottom: 5 }}
                        >
                            {/* <YAxis dataKey="availability" /> */}
                            <CartesianGrid
                                vertical={false}
                                horizontal={false}
                                strokeDasharray="#FFFFFF"
                                fill="rgba(217, 217, 217, 0.5)"
                            />
                            <Tooltip content={<CustomTooltip />} />
                            <Line
                                type="monotone"
                                dataKey="availability"
                                stroke="#00A3E0"
                                strokeWidth={2}
                                dot={{ stroke: '#00A3E0', strokeWidth: 5 }}
                                activeDot={{ r: 4 }}
                            />
                            <XAxis
                                dataKey="hour"
                                interval="preserveStartEnd"
                                tickFormatter={formatXAxis}
                                // scale="time"
                                // type="time"
                                style={{ fontSize: '10px' }}
                            />
                        </LineChart>
                    ) : (
                        <div className="empty-graph-data-display">
                            <p> No Data to show. Graph error.</p>
                        </div>
                    )}
                </div>
            )}
        </>
    )
}
