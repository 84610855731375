/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react'
import { useState, useEffect } from 'react'
import axios from 'axios'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import { ButtonGroup } from 'react-bootstrap'
import Button from '@mui/material/Button'
import Aws from '../../CloudPlatform_Images/aws.png'
import Cmp from '../../CloudPlatform_Images/cmp.png'
import Gcp from '../../CloudPlatform_Images/gcp.png'
import Azure from '../../CloudPlatform_Images/azure.png'
import TableHealth from '../HealthCard/HealthCard'
import '../NavBar/navbar.css'
import '../../LoaderSpinner/loaderspinner.css'
import { Menu, MenuItem, Popper, Select, styled } from '@mui/material'
import TextField from '@mui/material/TextField'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { useSelector } from 'react-redux'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import Tooltip from '@mui/material/Tooltip'
import Fade from '@mui/material/Fade'
import AWSHealthDashboard from '../AWShealthDashboard/awsHealthDashboard'
import useMediaQuery from '@mui/material/useMediaQuery';
import { StyledPopper } from '../SubscriptionModal/utils/CustomAutocomplete/customAutocomplete'


const regions = [
    { region: 'Americas' },
    { region: 'Europe' },
    { region: 'Asia Pacific' },
]

const awsRegions = [
    { region: 'global', label: 'Global' },
    { region: 'north_america', label: 'North America' },
    { region: 'south_america', label: 'South America' },
    { region: 'europe', label: 'Europe' },
    { region: 'africa', label: 'Africa' },
    { region: 'asia_pacific', label: 'Asia Pacific' },
    { region: 'middle_east', label: 'Middle East' },
]

const regionsMapper = {
    0: 'Americas',
    1: 'Europe',
    2: 'Asia Pacific',
}

const categoryMapper = {
    0: 'CMP',
    1: 'CloudNetwork',
    2: 'Azure',
    3: 'AWS',
    4: 'GCP',
}
const deviceTypeMapper = [
    { label: 'Palo Alto', value: 'palo-alto' },
    { label: 'F5', value: 'F5' },
]
const awsRegionsMapper = {
    0: 'global',
    1: 'north_america',
    2: 'south_america',
    3: 'europe',
    4: 'africa',
    5: 'asia_pacific',
    6: 'middle_east',
}
const gcpRegions = [
    { region: 'global', label: 'Global' },
    { region: 'americas', label: 'Americas' },
    { region: 'europe', label: 'Europe' },
    { region: 'africa', label: 'Africa' },
    { region: 'asia_pacific', label: 'Asia Pacific' },
    { region: 'middle_east', label: 'Middle East' },
]

const gcpRegionsMapper = {
    0: 'global',
    1: 'americas',
    2: 'europe',
    3: 'africa',
    4: 'asia_pacific',
    5: 'middle_east',
}

const azureRegionMapper = {
    0: 'americas',
    1: 'europe',
    2: 'asia_pacific',
}
const azureRegions = [
    { region: 'americas', label: 'Americas' },
    { region: 'europe', label: 'Europe' },
    { region: 'asia_pacific', label: 'Asia Pacific' },
]
function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const TabWithCount = ({ count }) => {
    return (
        <Box
            sx={{
                display: 'inline-flex',
                alignItems: 'center',
                padding: '0px 10px 0px 0px',
            }}
        >
            <p className="navbarLabels">{count}</p>
        </Box>
    )
}

export default function NewMobileNavBar() {
    // const [value, setValue] = React.useState(3);
    // Use Above one when Implementing User Preferences!!!
    const [value, setValue] = React.useState(0)
    const [cmpApiData, setCmpApiData] = useState({ data: [], empty: 'false' })
    const [cloudApiData, setCloudApiData] = useState({
        data: [],
        empty: 'false',
    })
    const [azureApiData, setAzureApiData] = useState({
        data: [],
        empty: 'false',
    })
    const [awsGcpAzureData, setAwsGcpAzureData] = useState({
        data: [],
        empty: 'false',
    })

    const [active2, setActive] = useState(0)
    const [loading, setLoading] = useState(false)
    const [searchString, setSearchString] = useState('')
    const [csp, setCSP] = useState('Azure')
    const [cachedApiData, setCachedApiData] = useState([])
    const [searchLabelText, setSearchLabelText] = useState('')
    const [categorySelector, setCategorySelector] = useState(
        deviceTypeMapper[0].value
    )
    const [cspSelectedRegion, setcspSelectedRegion] = useState('')
    const [selectedOption, setSelectedOption] = useState(null);



    // const userPreferences = useSelector(
    //   (state) => state?.userPreferences?.preferences?.ux_preferences
    // );
    // const mapCategoryToIndex = {
    //     CMP: 0,
    //     CloudNetwork: 1,
    //     Azure: 2,
    //     AWS: 3,
    //     GCP: 4,
    // }

    // const mapRegiontoIndex = {
    //     Americas: 0,
    //     Europe: 1,
    //     'Asia Pacific': 2,
    // }

    // const deviceTypePreferenceMapper = {
    //     paloAlto: 'palo-alto',
    //     F5: 'F5',
    // }

    // const filterOptions = createFilterOptions({
    //   matchFrom: "any",
    //   stringify: (option) => [option?._id, option?.data?.ip],
    // });

    const filterOptions = createFilterOptions({
        matchFrom: 'any',
        stringify: (option) => {
            const idValue = option?._id || option.serviceName || ''
            const ipValues = option?.data
                ?.map((item) => item?.ip || '')
                ?.filter(Boolean)
            return `${idValue} ${ipValues?.join(' ')}`
        },
    })

    // const returnCategoryIndex = async (userPreferences) => {

    // };

    //styled component

    // const syncAllUserPreferences = async () => {
    //   const result = userPreferences ? userPreferences?.split("-") : null;
    //   console.log(result);
    //   setValue(mapCategoryToIndex[result[1]]);
    //   setActive(mapRegiontoIndex[result[2]]);
    //   setCategorySelector(deviceTypePreferenceMapper[result[3]]);
    //   setCSP(result[4]);
    // };

    const serverUrl = process.env.REACT_APP_BACKEND_URL
    const fetchCMPData = async () => {
        setLoading(true)
        axios
            .get(
                `${serverUrl}/api/${categoryMapper[value]}` //CMP call
            )
            .then((res) => {
                if (res && res.data && res.data.length > 0) {
                    const result = res.data.sort((a, b) =>
                        a._id.localeCompare(b._id)
                    )
                    setCmpApiData({ data: result, empty: 'false' })

                    setCachedApiData({ data: result, empty: 'false' })
                } else {
                    setCmpApiData({ data: [], empty: 'true' })
                }
                setLoading(false)
            })
            .catch((err) => { })
            .finally(() => {
                setLoading(false)
            })
    }
    const fetchCloudData = async () => {
        setLoading(true)
        axios
            .get(
                `${serverUrl}/api/cloud/${categorySelector}/${regionsMapper[active2]}?csp=${csp}` //Cloud Network (Palo_Alto)
            )
            .then((res) => {
                // console.log(res);
                if (res && res.data.length > 0) {
                    setCloudApiData({ data: res.data, empty: 'false' })
                    setCachedApiData({ data: res.data, empty: 'false' })
                } else {
                    setCloudApiData({ data: [], empty: 'true' })
                }
                setLoading(false)
            })
            .catch((err) => { })
            .finally(() => {
                setLoading(false)
            })
    }

    const fetch_AWS_GCP_AZURE_Data = async () => {
        setLoading(true)
        const apiEndpoint = value === 3 ? 'aws' : value === 4 ? 'gcp' : 'azure'
        axios
            .get(`${serverUrl}/api/${apiEndpoint}/${cspSelectedRegion}`)
            .then((res) => {
                if (res && res.data && res.data.length > 0) {
                    // console.log(res.data)
                    setAwsGcpAzureData({ data: res.data, empty: 'false' })
                    setCachedApiData({ data: res.data, empty: 'false' })
                } else {
                    setAwsGcpAzureData({ data: [], empty: 'true' })
                }
            })
            .catch((err) => { })
            .finally(() => {
                setLoading(false)
            })
    }

    // const fetchOthersData = async () => {
    //     setLoading(true)
    //     axios
    //         .get(`${serverUrl}/api/others/${categoryMapper[value]}`)
    //         .then((res) => {
    //             if (res && res.data && res.data.length > 0) {
    //                 setApiData({ data: res.data, empty: 'false' })
    //                 setCachedApiData({ data: res.data, empty: 'false' })
    //             } else {
    //                 setApiData({ data: [], empty: 'true' })
    //             }
    //         })
    //         .catch((err) => {})
    //         .finally(() => {
    //             setLoading(false)
    //         })
    // }

    const fetchData = async () => {
        if (value === 0) {
            fetchCMPData()
        } else if (value === 1) {
            fetchCloudData()
        } else if (value === 2 || value === 3 || value === 4) {
            fetch_AWS_GCP_AZURE_Data()
        }
    }

    // useEffect(() => {
    //   const fetchDataAndUpdateState = async () => {
    //     if (userPreferences !== null && userPreferences !== undefined) {
    //       try {
    //         if (userPreferences) {
    //           await syncAllUserPreferences();
    //         }
    //         // await fetchData();
    //       } catch (error) {
    //         console.error("Error during data fetching and state update:", error);
    //       }
    //     } else {
    //       // await fetchData();
    //     }
    //   };
    //   fetchDataAndUpdateState();
    // }, [userPreferences]);

    useEffect(() => {
        switch (value) {
            case 0:
                setSearchLabelText('Search CMP Products')
                break
            case 1:
                setSearchLabelText(
                    `Search ${categorySelector === 'F5' ? 'F5' : 'Palo Alto'
                    } Devices by IP or Name`
                )
                break
            case 2:
                setSearchLabelText('Search Azure Services')
                break
            case 3:
                setSearchLabelText('Search AWS Services')
                break
            case 4:
                setSearchLabelText('Search GCP Services')
                break
            default:
                setSearchLabelText('Search Services')
        }
    }, [value, categorySelector])

    useEffect(() => {
        setActive(0)
        if (value === 2) {
            setcspSelectedRegion('americas')
        } else if ([3, 4].includes(value)) {
            if (cspSelectedRegion === 'global') {
                fetchData()
            } else {
                setcspSelectedRegion('global')
            }
        } else {
            fetchData()
        }
    }, [value])

    useEffect(() => {
        fetchData()
    }, [active2, categorySelector, csp])

    useEffect(() => {
        fetchData()
    }, [cspSelectedRegion])

    useEffect(() => {
        const interval = setInterval(() => {
            fetchData()
        }, 90000)

        return () => clearInterval(interval)
    }, [active2, value, categorySelector, csp])

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const setActiveButton = (index) => {
        setActive(index)
    }

    const theme = createTheme({
        palette: {
            primary: {
                main: '#00a3e0',
            },
            secondary: {
                main: 'rgb(124, 124, 124)',
            },
        },
    })

    const cspOptions = [
        { label: 'Azure', value: 'Azure' },
        { label: 'AWS', value: 'AWS' },
        { label: 'GCP', value: 'GCP' },
    ]

    const handleSearchChange = (e, v) => {
        // console.log(v?._id);
        setSearchString(v?._id || v?.serviceName || '')
    }

    useEffect(() => {
        setSearchString('')
        setSelectedOption(null);
    }, [value, categorySelector, cspSelectedRegion, gcpRegions, csp, active2])

    useEffect(() => {
        if (searchString === '' || !searchString) {
            if (value === 0) {
                setCmpApiData(cachedApiData)
            } else if (value === 1) {
                setCloudApiData(cachedApiData)
            } else {
                setAwsGcpAzureData(cachedApiData)
            }
        } else {
            const result = cachedApiData.data.filter((item) => {
                return (
                    item?._id?.toLowerCase() || item?.serviceName?.toLowerCase()
                ).includes(searchString.toLowerCase())
            })
            if (value === 0) {
                setCmpApiData({ data: result, empty: 'false' })
            } else if (value === 1) {
                setCloudApiData({ data: result, empty: 'false' })
            } else {
                setAwsGcpAzureData({ data: result, empty: 'false' })
            }
        }
    }, [searchString])

    const isMobileScreen = useMediaQuery('(min-width:320px) and (max-width:767px)')
    const isTabletScreen = useMediaQuery('(min-width:768px) and (max-width:1024px)')


    const getTopValue = () => {
        if (isMobileScreen) return '38px';
        if (isTabletScreen) return '50px';

    };

    return (

        <Box sx={{ width: '100%' }} className="service-insight-label" data-testid='new-mobile-navbar'>

            <Box>
                <Box
                    component="span"
                    sx={{ width: '100%', margin: '0px' }}
                    m={1}
                    display="flex"
                    flexDirection={['column']}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Tabs
                        TabIndicatorProps={{
                            style: {
                                display: 'flex',
                                justifyContent: 'center',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                position: 'absolute',
                                // left: "7px",
                                // width: "45px",
                                background: 'rgb(0, 163, 224)',
                                height: '4px',
                                top: getTopValue(),
                                // marginLeft: "5px",
                            },
                        }}
                        sx={{
                            width: '100%',
                            '& .MuiTabs-flexContainer': {
                                display: 'flex',
                                justifyContent: 'space-between',
                            },
                        }}
                        value={value}
                        onChange={handleChange}
                        aria-label="Tabs for Category Selection"
                    >
                        <Tab
                            sx={{ minWidth: '40px', padding: '13px 10px' }}
                            label={
                                <TabWithCount
                                    count="CMP"
                                    width="54px"
                                    height="40px"
                                ></TabWithCount>
                            }
                            {...a11yProps(0)}
                        />
                        <Tab
                            sx={{ minWidth: '40px', padding: '13px 10px' }}
                            label={
                                <TabWithCount
                                    count="Cloud"
                                    width="54px"
                                    height="40px"
                                ></TabWithCount>
                            }
                            {...a11yProps(0)}
                        />
                        <Tab
                            sx={{ minWidth: '40px', padding: '13px 10px' }}
                            label={
                                <TabWithCount
                                    count="Azure"
                                    width="48px"
                                    height="30px"
                                >
                                    Azure
                                </TabWithCount>
                            }
                            {...a11yProps(0)}
                        />
                        <Tab
                            sx={{ minWidth: '40px', padding: '13px 10px' }}
                            label={
                                <TabWithCount
                                    count="AWS"
                                    width="44px"
                                    height="30px"
                                >
                                    AWS
                                </TabWithCount>
                            }
                            {...a11yProps(0)}
                        />
                        <Tab
                            sx={{ minWidth: '40px', padding: '13px 10px' }}
                            label={
                                <TabWithCount
                                    count="GCP"
                                    width="34px"
                                    height="30px"
                                ></TabWithCount>
                            }
                            {...a11yProps(0)}
                        />
                    </Tabs>

                    {(value === 0 || [1, 2, 3, 4].includes(value)) && (
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                            <Autocomplete
                                disablePortal
                                key={value}
                                className="search_bar"
                                filterOptions={filterOptions}
                                id="combo-box-demo"
                                PopperComponent={StyledPopper} // Use the styled Popper component
                                getOptionLabel={(healthData) => healthData._id || healthData.serviceName || ''}
                                sx={{
                                    '.MuiOutlinedInput-root': {
                                        borderRadius: '10px',
                                        padding: '0px',
                                        border: '1px solid var(--search_bar_outline)',
                                    },
                                    width: 300,
                                    color: 'var(--hour_color)',
                                    '.MuiAutocomplete-inputRoot': {
                                        display: 'flex',
                                        alignItems: 'center',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        paddingRight: '30px !important', // Adjust padding to create space for the "x" mark
                                    },
                                }}
                                onChange={(e, v) => {
                                    setSelectedOption(v); // Update the selected option state
                                    handleSearchChange(e, v); // Call your existing change handler
                                }}
                                value={selectedOption}
                                options={
                                    value === 0
                                        ? cmpApiData.data
                                        : value === 1
                                            ? cloudApiData.data
                                            : awsGcpAzureData.data || []
                                }
                                renderInput={(params) => (
                                    <TextField
                                        className="autocomplete-search-text-field"
                                        {...params}
                                        label={searchLabelText}
                                        sx={{
                                            color: 'var(--hour_color)',
                                            '& .MuiInputLabel-root': {
                                                top: '-7px',
                                                color: 'var(--search_service_label_color)',
                                            },
                                            '& .MuiSvgIcon-root': {
                                                color: 'var(--search_service_label_color)',
                                            },
                                            '.MuiInputBase-input': {
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                paddingRight: '30px !important', // Ensure this matches the padding added to the input root
                                            },
                                        }}
                                    />
                                )}
                            />

                            {[2, 3, 4].includes(value) && (
                                <div className="region-dropdown-flex-mobile" style={{ marginLeft: '10px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <label className="region-selection-label-mobile">Region &nbsp;&nbsp;</label>
                                        <Select
                                            data-testid="region-selection-mobile"
                                            className='region-selection-mobile'
                                            MenuProps={{ MenuListProps: { style: { backgroundColor: 'var(--search_bar_dark_bg)', color: 'var(--body_color)' } } }}
                                            value={active2}
                                            onChange={(e) => {
                                                const selectedRegionMapper =
                                                    value === 2
                                                        ? azureRegionMapper
                                                        : value === 3
                                                            ? awsRegionsMapper
                                                            : gcpRegionsMapper;
                                                setcspSelectedRegion(selectedRegionMapper[e.target.value]);
                                                setActive(e.target.value);
                                            }}
                                            variant="outlined"
                                        >
                                            {(value === 2
                                                ? azureRegions
                                                : value === 3
                                                    ? awsRegions
                                                    : gcpRegions
                                            ).map((region, index) => (
                                                <MenuItem key={index} value={index}>
                                                    {region.label}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </Box>

                <ThemeProvider theme={theme}>
                    {value === 1 && (
                        <div className="region-dropdown-flex-mobile">
                            <div className="region-dropdown-flex-mobile-inner">
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '10px' }}>
                                    <p className="region-selection-label-mobile">Region &nbsp;&nbsp;</p>
                                    <Select
                                        className="region-selection-mobile"
                                        MenuProps={{ MenuListProps: { style: { backgroundColor: 'var(--search_bar_dark_bg)', color: 'var(--body_color)' } } }}
                                        value={active2}
                                        onChange={(e) => {
                                            setActive(e.target.value);
                                            setActiveButton(e.target.value);
                                        }}
                                        variant="outlined"
                                    >
                                        {regions.map((region, index) => (
                                            <MenuItem key={index} value={index}>
                                                {region.region}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', marginRight: '10px' }}>
                                    <p className="device-selection-label-mobile">Select Device &nbsp;&nbsp;</p>
                                    <Select
                                        className="device-selection-mobile"
                                        MenuProps={{ MenuListProps: { style: { backgroundColor: 'var(--search_bar_dark_bg)', color: 'var(--body_color)' } } }}
                                        onChange={(e) => {
                                            setCategorySelector(e.target.value);
                                        }}
                                        value={categorySelector}
                                    >
                                        {deviceTypeMapper.map((e, i) => (
                                            <MenuItem key={i} value={e.value}>
                                                {e.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p className="csp-selection-label-mobile">CSP &nbsp;&nbsp;</p>
                                    <Select
                                        className="device-selection-mobile"
                                        MenuProps={{ MenuListProps: { style: { backgroundColor: 'var(--search_bar_dark_bg)', color: 'var(--body_color)' } } }}
                                        onChange={(e) => {
                                            setCSP(e.target.value);
                                        }}
                                        value={csp}
                                    >
                                        {cspOptions.map((e, i) => (
                                            <MenuItem key={i} value={e.value}>
                                                {e.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </div>
                            </div>
                        </div>
                    )}
                </ThemeProvider>
            </Box>
            {loading ? (
                <div className="table-loader-container">
                    <div className="loader" />
                </div>
            ) : [2, 3, 4].includes(value) ? (
                <AWSHealthDashboard
                    apiData={awsGcpAzureData}
                    loading={loading}
                />
            ) : (
                <TableHealth
                    apiData={value === 0 ? cmpApiData : cloudApiData}
                    category={value}
                    loading={loading}
                />
            )}
        </Box>
    )
}
