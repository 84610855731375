export const regions = [
    { region: 'Americas' },
    { region: 'Europe' },
    { region: 'Asia Pacific' },
]

export const awsRegions = [
    { region: 'global', label: 'Global' },
    { region: 'americas', label: 'Americas' },
    { region: 'south_america', label: 'South America' },
    { region: 'europe', label: 'Europe' },
    { region: 'africa', label: 'Africa' },
    { region: 'asia_pacific', label: 'Asia Pacific' },
    { region: 'middle_east', label: 'Middle East' },
]
export const regionsMapper = {
    0: 'Americas',
    1: 'Europe',
    2: 'Asia Pacific',
}

export const categoryMapper = {
    0: 'CMP',
    1: 'CloudNetwork',
    2: 'Azure',
    3: 'AWS',
    4: 'GCP',
}
export const deviceTypeMapper = [
    { label: 'Palo Alto', value: 'palo-alto' },
    { label: 'F5', value: 'F5' },
]

export const mapCategoryToIndex = {
    CMP: 0,
    CloudNetwork: 1,
    Azure: 2,
    AWS: 3,
    GCP: 4,
}
export const awsRegionsMapper = {
    0: 'global',
    1: 'americas',
    2: 'south_america',
    3: 'europe',
    4: 'africa',
    5: 'asia_pacific',
    6: 'middle_east',
}

export const mapRegiontoIndex = {
    Americas: 0,
    Europe: 1,
    'Asia Pacific': 2,
}

export const deviceTypePreferenceMapper = {
    paloAlto: 'palo-alto',
    F5: 'F5',
}

export const cspOptions = [
    { label: 'Azure', value: 'Azure' },
    { label: 'AWS', value: 'AWS' },
    { label: 'GCP', value: 'GCP' },
]

export const getEnvProdCheck = () => {
    return process.env.REACT_APP_ENV === 'prod'
}

export const getEnvNpdCheck = () => {
    return process.env.REACT_APP_ENV === 'dev'
}

export const getEnvLocalCheck = () => {
    return process.env.REACT_APP_ENV === 'local'
}


export const getAppURL = () => {
    return getEnvProdCheck()
        ? process.env.REACT_APP_PRD_URL
        : process.env.REACT_APP_DEV_URL
}