import { url } from '../../serverUrl'
import { api } from '../../axiosWrapper'

export const updatePreferences = async (email, preferences) => {
    try {
        const response = await api.post(
            `${url}/api/others/updatePreferences`,
            {
                email: email,
                preference: preferences,
            },
            {
                timeout: 10000, // Set a timeout of 10 seconds (adjust as needed)
            }
        )
        return response.data
    } catch (error) {
        console.error(error)
        throw error // Re-throw the error to handle it in the calling function if needed
    }
}
