import React from 'react'

function CustomTabs({ regions, active, onClick }) {
    return (
        <div className="region-dropdown-flex">
            <div style={{ display: 'flex' }}>
                {regions.map((region, index) => (
                    <div key={index} className="aws-btn-containers">
                        {' '}
                        <button
                            className={
                                active === index
                                    ? 'btn-aws btn-aws-active'
                                    : 'btn-aws btn-aws-inactive'
                            }
                            onClick={() => {
                                onClick(index)
                            }}
                        >
                            {region?.label ?? region?.region}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CustomTabs
