import React from 'react'
import './index.css'
import { createRoot } from 'react-dom/client'
import App from './App'
import { PublicClientApplication } from '@azure/msal-browser'
import { msalConfig } from './config/authConfig'
import { Provider } from 'react-redux'
import store from './redux/store/store.js'
import 'bootstrap/dist/css/bootstrap.min.css'
const msalInstance = new PublicClientApplication(msalConfig)
const container = document.querySelector('#root')
const root = createRoot(container)

root.render(
    <Provider store={store}>
        <App msalInstance={msalInstance} data-testid="app"/>
    </Provider>
)
